<template>
  <div class="dashboards">
    <Loading :is_loading="loading" class="loading" message="Loading Dashboards..."/>
    <div v-if="!loading">
      <h3>My Reports</h3>
      <div class="control-bar">
        <b-button class="rebutton-outline rebutton-small" :disabled="selectedReportIds.length === 0"
                  @click="clickDelete">DELETE
        </b-button>
        <b-button class="rebutton-outline rebutton-small" :disabled="selectedReportIds.length === 0"
                  @click="clickRerun">RERUN
        </b-button>
      </div>
      <b-table striped hover :items="dashboards" :fields="tableFields" :current-page="currentPage"
               :per-page="perPage" @row-clicked="clickTableItem" ref="reportsTable"
      >
        <!--        Table Selection -->
        <template v-slot:head(selected)="data">
          <b-checkbox id="select-all" v-model="selectAll" @change="clickSelectAll"></b-checkbox>
        </template>

        <template v-slot:cell(selected)="row">
          <b-checkbox :checked="isSelected(row.item)" @change="selectReport(row.item)"></b-checkbox>
        </template>
        <template v-slot:cell(edit)="row">
          <!-- Link to the Dashboard Editor page for this dashboard -->
          <router-link :to="{ name: 'dashboard_editor', params: { dashboard_id: row.item.id } }"
          v-if="isAdmin">
            <font-awesome-icon icon="cog"></font-awesome-icon>
          </router-link>
        </template>
        <!--        Job Status -->
        <template v-slot:cell(job)="row">
          <font-awesome-icon :icon="statusIcons[row.item.job.status]" :id="'status-popover-'+row.item.id"
                             :style="statusStyles[row.item.job.status]"
          ></font-awesome-icon>
          <b-popover :target="'status-popover-'+row.item.id" triggers="hover" placement="bottom">
            <template #title>Status</template>
            {{ statusTooltips[row.item.job.status] }}
            <font-awesome-icon icon="sync" :id="'refresh-'+row.item.id" class="clickable"
                               v-if="refreshableStatuses.includes(row.item.job.status)"
                               :spin="refreshingReports.includes(row.item.id)" @click="clickRefreshStatus(row.item)"
            ></font-awesome-icon>
          </b-popover>
        </template>
        <!--       Datasets  -->
        <template v-slot:cell(job.parameters.datasets_query)="row">
          <span class="datasets" :id="'dataset-popover-'+row.item.id">
            {{ row.item.job.parameters.datasets_query.length }}
          </span>
          <b-popover :target="'dataset-popover-'+row.item.id" triggers="hover" placement="bottom">
            <template #title>Datasets</template>
            <ul>
              <li :key="index"
                  v-for="(dataset, index) in row.item.job.parameters.datasets_query">{{ dataset }}
              </li>
            </ul>
          </b-popover>
        </template>
      </b-table>

      <div class="page-control">
        <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
            class="flex-grow-0 w-auto">
        </b-form-select>
        <b-pagination
            v-model="currentPage"
            :total-rows="dashboards.length"
            :per-page="perPage"
            size="sm"
            class=""></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../components/Loading";
import DashboardService from "../services/dashboard.service";

export default {
  name: 'Dashboards',
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      dashboards: null,
      perPage: 25,
      pageOptions: [
        25,
        50,
        100
      ],
      currentPage: 1,
      tableFields: [
        {key: 'selected', label: '-'},
        {key: 'edit', label: ''},
        {key: 'name', sortable: true, tdClass: 'clickable'},
        {key: 'boundary_name', label: 'Boundary', sortable: true, tdClass: 'clickable'},
        {
          key: 'job.parameters.k', label: 'Clusters', sortable: true, tdClass: 'clickable',
          formatter: this.formatCluster, class: 'text-center'
        },
        {
          key: 'job.parameters.datasets_query', label: 'Datasets', formatter: this.formatDatasets,
          sortable: true, tdClass: 'clickable', class: 'text-center'
        },
        {
          key: 'job',
          label: 'Status',
          sortable: true,
          tdclass: 'clickable',
          formatter: this.formatStatus,
          class: 'text-center'
        },
        {key: 'created_date', sortable: true, tdclass: 'clickable', formatter: this.formatDate},
      ],
      statusIcons: {
        'Failed': 'times-circle',
        'Queued': 'circle',
        'InProgress': 'play-circle',
        'Complete': 'check-circle'
      },
      statusTooltips: {
        'Failed': 'Failed to Complete',
        'Queued': 'Queued',
        'InProgress': 'Running',
        'Complete': 'Complete'
      },
      statusStyles: {
        'Failed': {color: '#b70202'},
        'Queued': {color: '#002c96'},
        'InProgress': {color: '#002c96'},
        'Complete': {color: '#109102'}
      },
      // Report Statuses where the refresh icon appears
      refreshableStatuses: [
        'Queued',
        'InProgress'
      ],
      clusterValues: {
        3: 'Low',
        5: 'Medium',
        7: 'High'
      },
      selectedReportIds: [],
      selectAll: false,
      refreshingReports: [],
      autoStatusRefreshTimeout: null
    };
  },
  computed:{
    isAdmin() {
      return this.$store.state.auth.user.is_admin
    }
  },
  async mounted() {
    // Clear out the Schema and Data states if we've navigated back to the dashboard list.
    if (this.$store.state.dashboard.schema) {
      this.$store.commit('dashboard/updateSchema', null)
    }
    if (this.$store.state.dashboard.data) {
      this.$store.commit('dashboard/updateData', null)
    }
    await this.refreshReports()
  },
  beforeDestroy() {
    if (this.autoStatusRefreshTimeout) {
      clearTimeout(this.autoStatusRefreshTimeout)
    }
  },
  methods: {
    async refreshReports() {
      this.selectedReportIds = []
      this.selectAll = false
      // Timeout for old report list (milliseconds)
      // let timeOut = this.$store.state.dashboard.reportListTimestamp + 30000
      // if (!this.$store.state.dashboard.reportList || Date.now() > timeOut) {
      await this.$store.dispatch('dashboard/getDashboards')
      // }
      this.dashboards = this.$store.state.dashboard.reportList
      setTimeout(() => this.autoRefreshRun(), 10000)
      this.loading = false
    },
    clickTableItem(item) {
      this.$router.push('/home/reports/' + item.id)
    },
    formatDate(date) {
      return new Date(date).toLocaleString()
    },
    formatStatus(job) {
      // Take a report object, work out what it's job status should be.
      if (!job) {
        return 'Failed'   // TODO - Maybe differentiate missing jobs?
      } else {
        return job.status
      }
    },
    /***
     * Format Cluster numbers to match cluster sizing names in interface
     */
    formatCluster(clusters) {
      if (this.clusterValues.hasOwnProperty(clusters)) {
        return this.clusterValues[clusters]
      } else {
        return 'Custom'
      }
    },
    formatDatasets(datasets) {
      if (datasets) {
        return datasets.length
      } else {
        return 0
      }
    },
    /***
     * Return if a report is selected (used by the Table to show selected reports)
     */
    isSelected(report) {
      return this.selectedReportIds.includes(report.id)
    },
    /***
     * OnChange event funciton for report selection checkbox being toggled
     */
    selectReport(report) {
      if (this.isSelected(report)) {
        this.selectedReportIds = this.selectedReportIds.filter(x => x !== report.id)
      } else {
        this.selectedReportIds.push(report.id)
      }
    },
    /***
     * OnChange event function for 'Select All' checkbox being toggled. (in table header)
     */
    clickSelectAll(value) {
      if (value) {
        this.selectedReportIds = this.$refs.reportsTable.localItems.map(x => x.id)
      } else {
        this.selectedReportIds = []
      }
    },
    /***
     * Click event for Delete button.
     */
    async clickRerun() {
      let count = this.selectedReportIds.length
      let confirm = await this.$bvModal.msgBoxConfirm(`Are you sure you want to rerun ${count} reports?`, {
        title: 'Confirm Rerun',
        centered: true
      })
      if (confirm) {
        this.loading = true
        let responses = []
        for (let reportId of this.selectedReportIds) {
          try {
            let response = await DashboardService.rerunReport(reportId)
            if (response.data.success) {
              this.$bvToast.toast('Report Rerun Successfully', {
                title: 'Report Refreshed',
                variant: 'success',
                toaster: 'b-toaster-top-center'
              })
            } else {
              this.$bvToast.toast('Error, Failed to Rerun Report. Please try again later.', {
                title: 'Rerun Report Error',
                variant: 'danger',
                toaster: 'b-toaster-top-center'
              })
            }
          } catch (e) {
            console.log(e)
          }
        }
        await this.refreshReports()
        this.loading = false
      }
    },
    /***
     * Click Event for Rerun button
     */
    async clickDelete() {
      let count = this.selectedReportIds.length
      let confirm = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${count} reports?`, {
        title: 'Confirm Report Delete',
        okVariant: 'danger',
        centered: true
      })
      if (confirm) {
        this.loading = true
        for (let reportId of this.selectedReportIds) {
          try {
            let response = await DashboardService.deleteReport(reportId)
            if (response.data.success) {
              this.$bvToast.toast('Report Deleted Successfully', {
                title: 'Report Deleted',
                variant: 'success',
                toaster: 'b-toaster-top-center'
              })
            } else {
              this.$bvToast.toast('Error, Failed to Deleted Report. Please try again later.', {
                title: 'Delete Report Error',
                variant: 'danger',
                toaster: 'b-toaster-top-center'
              })
            }
          } catch (e) {
            console.log(e)
          }
        }
        await this.refreshReports()
        this.loading = false
      }
    },
    /**
     * Click Event for 'refresh' icon in the Report Status Popover
     * Triggers a manual refresh of the job status
     */
    async clickRefreshStatus(report) {
      this.refreshingReports.push(report.id)
      await this.refreshJobStatus(report)
      this.refreshingReports = this.refreshingReports.filter(x => x !== report.id)
    },
    /**
     * Refresh the Job status of a report.
     * Retrieves new data from the server and mutates the report object to show the new status (which updates the table)
     */
    async refreshJobStatus(report) {
      let resp = await DashboardService.reportStatus(report.id)
      if (resp.data) {
        report.job = resp.data
      }
    },
    /***
     * Auto-Refresh Job status of reports that are incomplete.
     */
    autoRefreshRun() {
      // NOTE: The bvTable occasionally doesn't provide it's 'localItems' prop, but I'm using this so that if we switch
      // to paginated data with a provider function, it still works smoothly.
      let incompleteReports = this.$refs.reportsTable.localItems.filter(
          x => this.refreshableStatuses.includes(x.job.status))
      if (incompleteReports.length > 0) {
        // console.log('Incomplete Reports: ', incompleteReports)
        for (let report of incompleteReports) {
          this.refreshJobStatus(report)
        }
        // Check if there are any left in incomplete states
        if (this.$refs.reportsTable.localItems.some(x => this.refreshableStatuses.includes(x.job.status))) {
          setTimeout(() => this.autoRefreshRun(), 10000)
        }
      }
    }
  }
};
</script>

<style scoped>

.dashboards {
  padding: 40px;
}

.control-bar {
  display: flex;
  flex-direction: row-reverse;
}

.dashboard_preview a {
  margin-top: auto; /* pushes to bottom */
  color: white;
}

.dashboard_preview:hover a {
  text-decoration: none;
  color: #0056b3;
}

h3 {
  font-size: 32pt;
}

.page-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.rebutton-small {
  font-size: 0.75em;
  font-weight: 500;
  padding: 10px 15px;
  margin: 10px;
}

.datasets {
  font-weight: 600;
  color: #023856;
  text-decoration: underline;
}

ul li {
  color: #023856;
  font-weight: 500;
}

</style>
